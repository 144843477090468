import React from "react"
import { Link } from "gatsby"
import simpleScroll from "simple-scroll"
import { Compass, ArrowRight } from "react-feather"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import bottomImage from "../images/outside-drinks.png"
import paintmark from "../images/paint.png"
import styles from "./index.module.css"

const IndexPage = () => {
  const CustomForm = ({ status, message, onValidated }) => {
    let email, hiddenTag
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        SIGNUPLOC: hiddenTag.value,
      })

    return (
      <div className={styles.customForm}>
        {status === "sending" && (
          <div style={{ color: "blue" }} className={styles.message}>
            sending...
          </div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            className={styles.message}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            className={styles.message}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          ref={node => (email = node)}
          type="email"
          placeholder="Enter your email address"
        />
        <input
          ref={node => (hiddenTag = node)}
          type="text"
          defaultValue="SHOPPED"
          style={{ display: "none" }}
        />
        <button onClick={submit}>Submit</button>
      </div>
    )
  }

  const url =
    "https://thisisundefined.us19.list-manage.com/subscribe/post?u=98bc2895d60b2f125e79385de&amp;id=f5b0225062"

  const textStripContent = [
    <div className={styles.textStripItem} key="1">
      Check out <Link to="/build">Shopped Build</Link>: One-off, affordable
      services to help you get the most out of Shopify.
    </div>,
    <div className={styles.textStripItem} key="2">
      Need help with your store?
    </div>,
  ]

  const textStripContainer = (
    <div className={styles.textStripContainer}>
      {textStripContent} {textStripContent}
    </div>
  )

  const scrollDown = ev => {
    ev.preventDefault()
    simpleScroll.element("theList", 50, 300)
  }

  return (
    <Layout>
      <SEO title="Outside Drinks" />
      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles.hashtag}>#OUTSIDEDRINKS</h3>
          <h1 className={styles.mainHeading}>
            The Best Boozers with Outside Space
            <br />
            for Drinking in London
          </h1>
          <p className={styles.subheading}>
            Browse our bountiful list of beer gardens and find the perfect spot
            to quench your thirst
          </p>
          <button className={styles.button} onClick={scrollDown}>
            <Compass />
            Begin your search
          </button>

          <img className={styles.paintmark} src={paintmark} />
        </div>
        <img className={styles.bottomImage} src={bottomImage} />
        <div className={styles.listContainer}>
          <h2 id="theList" className={styles.sectionHeading}>
            The list.
          </h2>
          <p className={styles.subheading}>
            Over 300 top-notch watering holes for you to browse at your leisure
            (or in a blind panic when you're gasping for a bevvy.)
          </p>
          <iframe
            className="airtable-embed"
            src="https://airtable.com/embed/shrtwh4BJHs29oCYI?backgroundColor=teal&layout=card&viewControls=on"
            frameBorder="0"
            width="100%"
            height="533"
            style={{ background: "transparent", border: "1px solid #ccc" }}
          ></iframe>
        </div>
        <div className={styles.formContainer}>
          <h2 className={styles.sectionHeading}>Newsletter</h2>
          {/* <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          /> */}
          <iframe
            width="540"
            height="600"
            src="https://30a10ea4.sibforms.com/serve/MUIEALilYT1BJW6XjEaZf40J52XT-lTvQsbUhhWU4v7_rIl82U07sILKh786_7gKEvTzpXMx_Dx0ZJ8lpNB9crCOkx5kYC8MltE9dsXc6OwY6NAnAs50pEQL0bBLzZHYiVyRTcbRRXOCjhRMj2p56-KZcv08xqksBPgrANj-Ftliil1uK-ghmiI8818XOxbHLNM6lRxlD_d0GFcd"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "100%",
            }}
          ></iframe>
        </div>
        <script
          type="text/javascript"
          src="https://platform-api.sharethis.com/js/sharethis.js#property=5f3167549b884400124df77c&product=inline-share-buttons"
          async="async"
        ></script>
        {/* <div className={styles.textStrip}>
          <div className={styles.textStripSlider}>
            {textStripContainer}
            {textStripContainer}
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default IndexPage
